.images{
    display: grid;
    flex: 1 1;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;

}
.images>img{
    object-fit: cover ;
}
.images:nth-child(1){
    width: 12rem;
    grid-row: 1/3;
    height: 28rem;
}
.images:nth-child(2){
    width: auto;
    height: 16rem;
    grid-column: 2/4;
}
.images:nth-child(3){
    width: 14rem;
    grid-column: 2/3;
    grid-row: 2;
}
.images:nth-child(4){
    width: 10rem;
    grid-column: 3/4;
    grid-row: 2;
}
input{
    height: 27px;
    width: auto;
    padding: 4px;
}
.btn{
    height: 37px;
}
.email-container{
    margin: 20px;
}