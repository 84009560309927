.body{
    display: flex;
    flex-direction: row;
}
.left-h{
    flex: 3;
    display: flex;
    flex-direction: column;
    background-color: rgb(95, 91, 87);
}
.right-h{
    flex: 1;
    background-color: orange;
}
.Header{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    
    margin: 0px;
}
.logo{
    background-color: transparent;
    height: 3rem;
    width: 10rem;
    padding-left: 2rem;
    padding-top: 2rem;
}
.Header-menu{
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding-top: 2rem;
    color: white;
}
.Header-menu>li:hover{
    cursor: pointer;
    color: brown;

}
.second-heading{
    background-color: rgb(54, 53, 53);
    border-radius: 2.5rem;
    width: fit-content;
    padding: 10px;
    margin-top: 4rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    color: beige;
    position: relative;
}
.second-heading>div{
    background-color: orange;
    width: 3.4rem;
    height: 60%;
    position: absolute;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
    opacity: 0.75;
}
.second-heading>span{
    z-index: 2;
    color: rgb(255, 254, 252);
    font-weight: 500;
    text-transform: uppercase;
    
}

.div-1{
    display: flex;
    flex-direction: row;
    gap: 20rem;
}


.Heading{
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
    text-overflow: inherit;
    gap: 1.5rem;
    background-color: rgb(95, 91, 87);
}
.stroke-text{
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}
.Heading>div:nth-of-type(3){
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 1px;
    color: azure;
    font-size: 1rem;
}
.figure{
    display: flex;
    gap: 2rem;
}
.figure>div{
    display: flex;
    flex-direction: column;

}
.figure>div>span:nth-of-type(1){
    color: azure;
    font-size: 2rem;
}
.figure>div>span:nth-of-type(2){
    color: rgb(195, 178, 178);
    text-transform: uppercase;
}
.hero-button{
    display: flex;
    gap: 2rem;
}
.btn{
    font-size: medium;
    background-color: darkorange;
    border: 2px solid darkorange;
    color: aliceblue;
    font-weight: 200;
}
.btn:nth-of-type(2){
    background-color: grey;
}
.right-btn1{
    background-color: aliceblue;
    margin-top: 20px;
    margin-left: 230px;
    width: fit-content;
    height: 40px;
    border-color: aliceblue;
    padding: 10px;
}
.Heart-div{
    margin-left: 130px;
    margin-top: 10px;
    background-color: grey;
    width: fit-content;
    padding: 10px;
    border-radius: 0.5rem;
}
.Heart-div>div:nth-of-type(2){
    color: aliceblue;
    font-size: 1.5rem;
}
.Heart-div>div:nth-of-type(1){
    color: aliceblue;
    /* font-size: 1.5rem; */
}
.calories{
    height: 60%;
    width: 15%;
    padding: 10px;
}
.figure>div:nth-of-type(4){
    display: flex;
    flex-direction: row;
    gap: 0rem;
}
.calories-pro{
    border: 2px solid grey;
    
}
.figure>div:nth-of-type(4)>div {
    text-align: center;
    padding: 2px;
    color: azure;
    font-size: 1.25rem;
    
}