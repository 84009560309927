body{
    background-color: black;
}
.second-page-class1{
    color: white;
    font-size: 2rem;
    text-transform: uppercase;
    font-style: italic;
    display: flex;
    gap: 6rem;
    font-weight: bold;
}
.stroke-text{
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}
.second-page-class2{
    color: white;
    display: flex;
    gap: 2rem;
    margin: 20px;
    
}
.second-page-class2>div{
    border: 2px solid;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 20px;
    background-color: rgb(59, 54, 54);
}